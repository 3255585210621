<template>
  <el-card class="box-card">
    <vab-query-form-left-panel :span="24">
      <el-button class="button" icon="el-icon-plus" type="warning" @click="handleEdit" size="small">新增标签</el-button>
    </vab-query-form-left-panel>

    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="100">
      </el-table-column>
      <el-table-column
          align="center"
          prop="name"
          label="名字">
      </el-table-column>
      <el-table-column
          align="center"
          prop="region"
          label="关联区域">
        <template #default="{ row }">
          <span v-if="row.children">
            <span v-for="(item,index) in row.children" :key="index">
              <span v-if="index < row.children.length-1">{{ item.name }},</span>
              <span v-else>{{ item.name }}</span>
            </span>
          </span>
          <span v-else>暂无区域</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--    <el-pagination
            background
            :current-page="queryForm.page"
            :layout="layout"
            :page-size="queryForm.pageSize"
            :total="count"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
        />-->
    <Edit ref="Edit" @fetch-data="fetchData"/>
  </el-card>
</template>
<script>
import Edit from './components/areaLabelEdit'
import {apiDeleteAreaLabel, apiGetAreaLabel} from '@/request/api'

export default {
  components: {Edit},
  props: [],
  data() {
    return {
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        id: '',
      },
      current: 0,
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    // 删除
    handleDelete(row) {//apiDeleteAreaLabel
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteAreaLabel({id: row.id}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetAreaLabel(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data
        }
      })
      this.listLoading = false
    },
  }
}

</script>
<style>
.button {
  margin-top: 20px;
}

.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.el-form-item {
  margin-bottom: 0;
}

.el-dropdown {
  margin-right: 10px;
}

.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
}

.el-row {
  margin-bottom: 15px;
}
</style>
