<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="550px"
    @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="名字" prop="name">
        <el-input v-model="form.name" size="small" />
      </el-form-item>
      <el-form-item
        style="margin-bottom: 20px"
        label="关联区域"
        prop="city_ids_json"
      >
        <el-cascader
          v-model="form.city_ids_json"
          :options="apiRegion"
          :props="{
            multiple: true,
            label: 'name',
            value: 'id',
          }"
          clearable
          filterable
          collapse-tags
          style="width: 100%"
        ></el-cascader>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { apiGetAreaMsg, apiUpdateAreaLabel } from "@/request/api";

export default {
  name: "AdminEdit",
  data() {
    return {
      coustate: true,
      query_time: [],
      apiRegion: [],
      form: {
        id: "",
        name: "",
        city_ids_json: "",
      },
      rules: {
        name: [
          { required: true, trigger: "blur", message: "标签名称不能为空" },
        ],
        city_ids_json: [
          { required: true, trigger: "blur", message: "关联区域不能为空" },
        ],
      },
      title: "",
      dialogFormVisible: false,
    };
  },
  created() {
    this.requestRegion();
  },
  methods: {
    getArea(event) {
      console.log(event);
    },
    // 获取省份
    async requestRegion() {
      apiGetAreaMsg().then((res) => {
        if (res.code == 200) {
          this.apiRegion = res.data;
        }
      });
    },
    showEdit(row) {
      if (!row) {
        this.title = "添加";
      } else {
        this.title = "修改";
        const tempObj = Object.assign({}, row);
        this.form = {
          id: tempObj.id,
          name: tempObj.name,
          city_ids_json: [],
        };
        for (let i = 0; i < tempObj.children.length; i++) {
          this.form.city_ids_json.push(tempObj.children[i].id);
        }
      }
      this.dialogFormVisible = true;
    },
    selectionStatus(event) {
      console.log(event);
      if (event == 2) {
        this.coustate = true;
      } else {
        this.coustate = false;
      }
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
    //保存新增、修改
    save() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          var list = []
          this.form.city_ids_json.forEach(item => {
            list.push(item[item.length-1])
          })
          this.form.city_ids_json = list
          console.log(this.form)
          apiUpdateAreaLabel(this.form).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.message);
              this.$emit("fetch-data");
              this.close();
            } else {
              this.$message.error(res.message);
              this.close();
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
